import React, { useState, useRef } from "react"
import styled from "styled-components"
//Sections
import { Section } from "./sections.js"
//Elements
import { SectionTitle, SectionItemTitle } from "../typography.js"
import { CheckedRight, Absent } from "../elements/checkmark.js"
import { SubmitButton } from "../elements/buttons.js"

//https://codepen.io/AllThingsSmitty/pen/WjZVjo

const TheCatch = ({ title }) => {
  const [showContent, setShow] = useState(false)
  const buttonRef = useRef(null)
  const finalTitleRef = useRef([])
  const finalTitleWrapRef = useRef(null)
  const sectionRef = useRef(null)
  //Stage 1 animation: Title animations.
  let initialTitle = ["Just ", <em>one </em>, "catch."]
  // let finalTitle = [
  //   "It",
  //   "works",
  //   "when",
  //   "you",
  //   "follow",
  //   "instructions",
  //   "completely.",
  // ]
  const defaultTitle = ["You", "needa", "do", "yo'", "shit,", "qurl."]
  let finalTitle
  if (!title) {
    finalTitle = defaultTitle
  } else {
    finalTitle = title
  }
  const handleTitleRef = el => {
    if (finalTitleRef.current.length < finalTitle.length) {
      finalTitleRef.current.push(el)
    }
  }
  const handleClick = () => {
    setShow(true)
    // hideButton()
    setTimeout(() => {
      animateTitle()
    }, 1000)
  }
  const hideButton = () => {
    if (typeof window !== "undefined") {
      window.setTimeout(() => {
        buttonRef.current.style.display = "none"
      }, 200)
    }
  }
  const animateTitle = () => {
    finalTitleRef.current.forEach((ref, index) => {
      ref.style.display = "inline"
      if (typeof window.setTimeout !== "undefined") {
        window.setTimeout(() => {
          ref.style.opacity = "1"
        }, 100 * (index + 4))
        // if (index === finalTitleRef.current.length - 1) {
        //   window.setTimeout(() => {
        //     finalTitleWrapRef.current.style.top = "-50px"
        //     // reduceSectionPaddingTop()
        //     // increaseSectionHeight()
        //   }, 100 * (index + 1 + 4))
        // }
      }
    })
  }
  //Stage 2 animation: Section Height increases
  const increaseSectionHeight = () => {
    if (typeof window !== "undefined") {
      window.setTimeout(() => {
        sectionRef.current.style.height = "500px"
      }, 4000)
    }
  }
  const reduceSectionPaddingTop = () => {
    if (typeof window !== "undefined") {
      window.setTimeout(() => {
        sectionRef.current.style.paddingTop = "0px"
      }, 4000)
    }
  }
  //Stage 3 animation: Content appears.

  return (
    <Section
      ref={sectionRef}
      style={{
        borderRadius: "15px",
        background: "#F50057",
        height: showContent ? "260px" : "260px",
        color: "#fff",
        paddingBottom: "116px",
        transition: "all 1s ease-in",
      }}
    >
      {/* "rgb(252,192,212)" */}
      <SectionTitle
        center="center"
        style={{
          marginBottom: "50px",
          marginTop: "-20px",
          transition: "all 1s ease-in",
          width: "90%",
          margin: "0 auto",
        }}
      >
        {!showContent ? (
          initialTitle.map((word, index) => (
            <span key={"titleWord" + index}>{word}</span>
          ))
        ) : (
          <div
            ref={finalTitleWrapRef}
            style={{
              transition: `all 1s ease-in`,
              transitionDelay: "3s",
              position: "relative",
              top: "60px",
            }}
          >
            {finalTitle.map((word, index) => (
              <span
                key={"titleWord" + index}
                style={{
                  transition: `all ${0.75 * (index + 1)}s ease-in`,
                  display: "none",
                  opacity: "0",
                }}
                ref={el => {
                  handleTitleRef(el)
                }}
              >
                {word + " "}
              </span>
            ))}
          </div>
        )}
      </SectionTitle>

      <div style={{ margin: "50px auto 0px auto" }} ref={buttonRef}>
        <SubmitButton
          style={{
            width: "5.5em",
            color: "black",
            margin: "0px auto 0px auto",
            background: "white",
            fontWeight: "500",
            opacity: !showContent ? "1" : "0",
            transition: "all 0.3s ease-in",
          }}
          onClick={() => handleClick()}
        >
          What's that?
        </SubmitButton>
      </div>
    </Section>
  )
}

export default TheCatch
